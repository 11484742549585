import * as React from "react";
import { useLocalization } from "gatsby-theme-i18n"

const translations = {
    "Contact"                                                                                                      : "Επικοινωνία",
    "Home"                                                                                                         : "Αρχική",
    "About"                                                                                                        : "Εμείς",
    "This site uses cookies."                                                                                      : "Αυτή η ιστοσελίδα χρησιμοποιεί cookies.",
    "Decline"                                                                                                      : "Άρνηση",
    "Accept"                                                                                                       : "Αποδοχή",
    "Learn more"                                                                                                   : "Μαθε περισσότερα",
    "First Name"                                                                                                   : "Όνομα",
    "Last Name"                                                                                                    : "Επώνυμο",
    "Phone"                                                                                                        : "Τηλέφωνο",
    "Message"                                                                                                      : "Μήνυμα",
    "Send"                                                                                                         : "Αποστολή",
    "Feel free to email or call us to talk about your requirements and find out more about what we can do for you.": "Μην διστάσετε να μας στείλετε mail ή να μας καλέσετε για να συζητήσουμε τι χρειάζεστε και τι μπορούμε να κάνουμε για εσάς.",
    "Home, Hotel & Marine Bespoke Furniture"                                                                       : "Έπιπλα Σπιτιού, Ξενοδοχείου και Πλοίων κατά παραγγελία",
    "View Projects"                                                                                                : "Δες τα Project"
}

export function translationMap( string: string, locale ) {
    if (locale === 'en') {
        return string
    } else {
        return translations[string] ?? string
    }
}

export default function t( string: string ) {
    const { locale } = useLocalization();
    return translationMap( string, locale )
}
