import React from "react";
import { CookiesContextProvider } from "@emmbrand/gatsby-plugin-analytics-and-consent";
import { AppStateProvider } from "./src/AppState";
import { GatsbyBrowser } from "gatsby";

const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ( { element } ) => {
    return (
        <CookiesContextProvider>
            <AppStateProvider>
                { element }
            </AppStateProvider>
        </CookiesContextProvider>
    )
};

export default wrapRootElement
