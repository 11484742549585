import * as React from "react"
import { Dispatch, FunctionComponent, memo, useContext, useEffect, useReducer } from "react"

interface IAppState extends IReducer {
    dispatch: Dispatch<ActionType>
}

interface IReducer {
    drawerOpen: boolean
    theme?: string
}

type ActionType = {
    type: 'TOGGLE_THEME' | 'INIT_THEME' | 'TOGGLE_DRAWER' | 'CLOSE_DRAWER'
    args?: any
}

function appStateReducer( state: IReducer, action: ActionType ) {
    let currentTheme = localStorage.getItem( "currentTheme" );
    switch (action.type) {
        case 'TOGGLE_DRAWER':
            return {
                ...state,
                drawerOpen: !state.drawerOpen
            }
        case 'CLOSE_DRAWER':
            return {
                ...state,
                drawerOpen: false
            }
        case 'TOGGLE_THEME':
            currentTheme = state.theme === "dark" ? "light" : "dark"
            document.body.dataset.theme = currentTheme
            localStorage.setItem( "currentTheme", currentTheme )
            return {
                ...state,
                theme: currentTheme
            };
        case 'INIT_THEME':
            if (!currentTheme) {
                currentTheme = "light";
            }
            document.body.dataset.theme = currentTheme
            localStorage.setItem( "currentTheme", "light" )
            return {
                ...state,
                theme: "light"
            };
        default:
            throw new Error();
    }
}


export const AppState = React.createContext<IAppState>( {
    drawerOpen: false,
    theme     : undefined,
    dispatch  : () => {
    }
} );

export const AppStateProvider: FunctionComponent = memo( ( { children } ) => {
    const [ state, dispatch ] = useReducer( appStateReducer, {
        drawerOpen: false,
        theme     : undefined
    } );
    useEffect( () => dispatch( { type: 'INIT_THEME' } ), [] )
    return (
        <AppState.Provider value={ {
            drawerOpen: state.drawerOpen,
            theme     : state.theme,
            dispatch  : dispatch
        } }>
            { children }
        </AppState.Provider>
    )
} )

export function useAppState() {
    return useContext( AppState )
}
