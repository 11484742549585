import React, { FC, HTMLAttributeAnchorTarget, ReactNode } from "react";
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { makeButtonClass, smoothScroll } from "./btnUtils";
import { joinIgnoreEmpty } from "../../../utilities";

type IButton = {
    className?: string
    look?: 'fill' | 'outline' | 'minimal' | 'empty' | 'line' | 'liquid' | 'navLink'
    color?: string
    icon?: ReactNode
    disabled?: boolean
    onClick?: () => void
    title?: string
    tabIndex?: number
    type?: 'submit' | 'reset' | 'button'
} & (
    | { href?: false; target?: undefined; to: string; scrollTo?: false; isNavLink?: true; partiallyCurrent?: true; activeCls?: string; inactiveCls?: string }
    | { href?: false; target?: undefined; to?: false; scrollTo: string; isNavLink?: false; partiallyCurrent?: false; activeCls?: false; inactiveCls?: false }
    | { href?: false; target?: undefined; to?: false; scrollTo?: false; isNavLink?: false; partiallyCurrent?: false; activeCls?: false; inactiveCls?: false }
    | { href: string; target?: HTMLAttributeAnchorTarget; to?: false; scrollTo?: false; isNavLink?: false; partiallyCurrent?: false; activeCls?: false; inactiveCls?: false }
    )


const Button: FC<IButton> = ( {
                                  className,
                                  to,
                                  onClick,
                                  children,
                                  look = "fill",
                                  color = "accent",
                                  icon,
                                  disabled,
                                  title,
                                  tabIndex,
                                  scrollTo,
                                  isNavLink,
                                  partiallyCurrent,
                                  activeCls = "active",
                                  inactiveCls,
                                  type,
                                  href,
                                  target
                              } ) => {

    const cls = makeButtonClass( color, className )[look]

    const navCls = joinIgnoreEmpty( makeButtonClass( color ).navLink, className )

    const navLinkProps = ( { isCurrent, isPartiallyCurrent } ) => {
        if (!isNavLink) return
        return {
            className: joinIgnoreEmpty( navCls, (partiallyCurrent ? isPartiallyCurrent ? activeCls : inactiveCls : isCurrent ? activeCls : inactiveCls) )
        }
    }
    if (to) {
        return (
            <Link to={ to } onClick={ onClick } className={ cls } disabled={ disabled } title={ title } tabIndex={ tabIndex }
                  getProps={ navLinkProps }>
                { icon }
                { children }
            </Link>
        )
    }
    if (scrollTo) {
        return (
            <button onClick={ () => smoothScroll( scrollTo ) } className={ cls } disabled={ disabled } title={ title } tabIndex={ tabIndex }>
                { children }
            </button>
        )
    }
    if (href) {
        return (
            <a href={ href } className={ cls } title={ title } tabIndex={ tabIndex } target={ target } rel={ target === "_blank" ? "noopener noreferrer" : "" }>
                { icon }
                { children }
            </a>
        )
    }
    return (
        <button onClick={ onClick } className={ cls } disabled={ disabled } title={ title } tabIndex={ tabIndex } type={ type }>
            { children }
        </button>
    )
}

export default Button
