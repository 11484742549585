import React from "react";
import { AnimatePresence } from "framer-motion";
import { CookiesBanner } from "@emmbrand/gatsby-plugin-analytics-and-consent";
import { translationMap } from "./src/i18n";
import { GatsbyBrowser } from "gatsby";
import { TypeLocale } from "./src/types";
import Button from "./src/components/ui/button/Button";

const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ( { element, props } ) => {
    const locale = props.pageContext.locale as TypeLocale
    return (
        <>
            <CookiesBanner>
                { ( { acceptAllCookies, declineAllCookies, dismissBanner } ) => (
                    <div className={ "container fixed-x--center round bottom--10 w--100 z--8 flex--center p--md border--md border--border bg--bg elevation--3" }>
                        <Button look={ "minimal" } onClick={ dismissBanner }>
                            <svg className={ "flex--center fill--textDimmed hover:fill--text transition--colors mr--md cursor--pointer" } xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24">
                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                            </svg>
                        </Button>
                        <p className={ "m--0" }>{ translationMap( "This site uses cookies.", locale ) } <Button look={ "minimal" } target={"_blank"} href={ "https://www.modplus.eu/privacy-policy/" }>Cookies Policy</Button></p>
                        <Button look={ "outline" } className={ "ml--auto" } onClick={ declineAllCookies }>{ translationMap( "Decline", locale ) }</Button>
                        <Button className={ "ml--sm" } onClick={ acceptAllCookies }>{ translationMap( "Accept", locale ) }</Button>
                    </div>
                ) }
            </CookiesBanner>
            <AnimatePresence exitBeforeEnter>
                <div className={"pt--header"} key={ props.location.pathname }>
                    { element }
                </div>
            </AnimatePresence>
        </>
    )
}

export default wrapPageElement
