export function makeButtonClass( color, extra?: string ) {
    const base = `btn inline-flex al__it--center jf__cnt--center cursor--pointer transition--colors text--bold ${ extra }`;
    return {
        fill   : `btn--fill ${ color } ${ base } px--lg py--sm border--md round`,
        outline: `btn--outline ${ color } ${ base } px--lg py--sm border--md round`,
        minimal: `btn--minimal ${ color } ${ base }`,
        navLink: `btn btn--nav-link ${ color } ${ extra } cursor--pointer transition--colors`,
        empty  : `btn ${ extra } cursor--pointer transition--colors`,
        line   : `btn--line ${ color } ${ base } text--uppercase`,
        liquid : `liquid-btn ${ color } ${ base } px--lg py--10 border--md round--circle`
    }
}

export function smoothScroll( id: string, offset: number = 90 ) {
    const element = document.getElementById( id );
    if (!element) {
        return
    }
    const top = window.scrollY + element.getBoundingClientRect().top - offset;
    window.scrollTo( {
        behavior: 'smooth',
        top
    } );
}
