import { Dispatch, SetStateAction, useEffect, useState } from "react";
//-------------------------------------------------------------------------------//
// String utils
//-------------------------------------------------------------------------------//
export function joinIgnoreEmpty( ...strings: any ) {
    return strings.filter( Boolean ).join( " " );
}

export function createLabel( name: string, operator: string = "-" ) {
    const addSpace = name.split( operator ).join( " " );
    return addSpace.charAt( 0 ).toUpperCase() + addSpace.substring( 1 ).toLowerCase();
}

export function imgAlt( imgSrc: string, after?: string ) {
    return imgSrc.split( "/" ).pop().replace( /.png|.jpg/g, "" ) + (after ? " " + after : "")
}

//-------------------------------------------------------------------------------//
// Get browser language
//-------------------------------------------------------------------------------//
export function getBrowserLanguage() {
    if (typeof navigator === `undefined`) {
        return "en";
    }
    const lang = navigator?.language?.split( "-" )[0];
    if (!lang) return "en";
    switch (lang) {
        case "el":
            return "el";
        default:
            return "en";
    }
}

//-------------------------------------------------------------------------------//
// Theme Changer
//-------------------------------------------------------------------------------//
export function useThemeChanger(): [ string, Dispatch<SetStateAction<string>> ] {
    const [ theme, setTheme ] = useState( "" );

    useEffect( () => {
        let currentTheme = localStorage.getItem( "currentTheme" );
        if (!currentTheme) {
            if (window.matchMedia && window.matchMedia( '(prefers-color-scheme: dark)' ).matches) {
                currentTheme = "dark";
            } else {
                currentTheme = "light";
            }
        }
        setTheme( currentTheme )
    }, [] )

    return [ theme, setTheme ]
}

//-------------------------------------------------------------------------------//
// Google ReCaptcha
//-------------------------------------------------------------------------------//
export function loadReCaptchaScript( shouldLoad, siteKey ) {
    if (!shouldLoad) {
        const script = document.getElementById( "recaptcha-script" );
        script && document.body.removeChild( script );
        return
    }
    const script = document.createElement( "script" );
    script.src = `https://www.google.com/recaptcha/api.js?render=${ siteKey }`;
    script.id = "recaptcha-script";
    document.body.appendChild( script );
}

//-------------------------------------------------------------------------------//
// Google Analytics
//-------------------------------------------------------------------------------//
export function loadGoogleAnalytics( shouldLoad: boolean, trackingID: string, callback?: () => void ) {
    const isDev = process.env.NODE_ENV === 'development';
    const existingScript = document.getElementById( "analytics-script" );
    if (isDev) {
        if (shouldLoad) {
            console.log( "Loaded Analytics" );
            callback && callback();
        }
        return;
    }
    if (!shouldLoad) {
        const script = document.getElementById( "analytics-script" )
        const config = document.getElementById( "analytics-config" )
        script && document.head.removeChild( script );
        config && document.head.removeChild( config );
        return
    }
    if (!existingScript) {
        //Create scripts
        const script = document.createElement( "script" );
        const config = document.createElement( "script" );
        //Set scripts ids
        script.id = "analytics-script";
        config.id = "analytics-config";
        script.src = `https://www.googletagmanager.com/gtag/js?id=${ trackingID }`;
        config.innerHTML = `
         window.dataLayer = window.dataLayer || [];
         function gtag(){dataLayer.push(arguments);}
         gtag('js', new Date());        
         gtag('config', '${ trackingID }', {
            send_page_view: false
         });
    `;
        //Append scripts
        document.head.prepend( script );
        document.head.prepend( config );
    }
    callback && callback();
}

export type ScreenBreakpoint = {
    phone?: number
    tablet?: number
    laptop?: number
    desktop?: number
}

export const useCheckForScreens = ( breakPoints?: ScreenBreakpoint ): { screen: string, isMobile: boolean, } => {
    const [ isMobile, setIsMobile ] = useState( false );
    const [ screen, setScreen ] = useState( "" );

    function checker() {
        const phone = breakPoints?.phone ? breakPoints.phone : 480;
        const tablet = breakPoints?.tablet ? breakPoints.tablet : 768;
        const laptop = breakPoints?.laptop ? breakPoints.laptop : 992;
        const desktop = breakPoints?.desktop ? breakPoints.desktop : 1200;
        let windowWidth = document.documentElement.clientWidth;
        if (windowWidth < phone) {
            setIsMobile( true );
            setScreen( 'phone' );
        } else if (windowWidth <= tablet) {
            setIsMobile( true );
            setScreen( 'tablet' )
        } else if (windowWidth < laptop) {
            setIsMobile( false );
            setScreen( 'laptop' )
        } else if (windowWidth >= desktop) {
            setIsMobile( false );
            setScreen( 'desktop' )
        }
    }

    useEffect( () => {
        checker();
        window.addEventListener( 'resize', checker );
        return () => {
            checker();
            window.removeEventListener( 'resize', checker );
        };
    } );

    return { isMobile, screen }
};
